import { TrackOrderQuery } from "shared/analytics/useTrackOrder/track-order.query.generated";
import { AnalyticsCustomer } from "utils/analytics/analytics.utils";

export const fromCustomer = (
  customer: TrackOrderQuery["customer"],
): AnalyticsCustomer | undefined =>
  customer
    ? {
        id: String(customer.id),
        firstName: customer.firstName || undefined,
        lastName: customer.lastName || undefined,
        phone: customer.phone || undefined,
        email: customer.email || undefined,
      }
    : undefined;
