import * as Types from '../../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TrackPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TrackPageQuery = { __typename?: 'query_root', customer?: Types.Maybe<{ __typename?: 'CustomerMetadata', id: number, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, email?: Types.Maybe<string>, phone?: Types.Maybe<string> }> };


export const TrackPageDocument = gql`
    query trackPage {
  customer: nest_getCustomerMetadata {
    id
    firstName
    lastName
    email
    phone
  }
}
    `;
export function useTrackPageQuery(baseOptions?: Apollo.QueryHookOptions<TrackPageQuery, TrackPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackPageQuery, TrackPageQueryVariables>(TrackPageDocument, options);
      }
export function useTrackPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackPageQuery, TrackPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackPageQuery, TrackPageQueryVariables>(TrackPageDocument, options);
        }
export type TrackPageQueryHookResult = ReturnType<typeof useTrackPageQuery>;
export type TrackPageLazyQueryHookResult = ReturnType<typeof useTrackPageLazyQuery>;
export type TrackPageQueryResult = Apollo.QueryResult<TrackPageQuery, TrackPageQueryVariables>;