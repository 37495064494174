import { useEffect } from "react";
import { match, P } from "ts-pattern";

export type AnalyticsCustomer = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
};

export type AnalyticsProduct = {
  product_id: string;
  sku: string;
  category: string;
  name: string;
  brand?: string;
  variant?: string;
  price?: number;
  quantity?: number;
  coupon?: string;
  position?: number;
  currency?: string;
};

export type AnalyticsProductList = {
  products?: AnalyticsProduct[];
  list_id?: string;
  category?: string;
};

export type AnalyticsOrder = {
  order_id: number;
  checkout_id?: string;
  affiliation?: string;
  value?: number;
  subtotal?: number;
  upfront?: number;
  upfrontWithTax?: number;
  total?: number;
  revenue?: number;
  shipping?: number;
  tax?: number;
  discount?: number;
  coupon?: string;
  currency?: string;
  products?: AnalyticsProduct[];
};

export type AnalyticsCoupon = {
  coupond_id: string;
  order_id?: string;
};

export const dataLayer = () => window?.dataLayer || [];

const injectEventId = (event: string, payload: Record<string, any>) =>
  match(payload)
    .with({ order_id: P.union(P.string, P.number) }, ({ order_id }) => ({
      ...payload,
      event_id: `${event}.${order_id}`,
    }))
    .otherwise(() => payload);

export const track = (event: string, props: Record<string, any> = {}) =>
  dataLayer().push({
    event,
    ...injectEventId(event, props),
  });

export const trackPageView = (customer?: AnalyticsCustomer) =>
  track("Viewed a Page", {
    path: location.pathname,
    referrer: document.referrer,
    search: location.search,
    title: document.title,
    url: location.href,
    customer,
  });

export const trackEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category: "engagement" | "lead";
  label: string;
  value?: number;
}) => track(action, { category, label, value });

export const trackCheckoutStarted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) =>
  track("Checkout Started", {
    ...order,
    customer,
  });

export const trackCheckoutStepViewed = (
  order: AnalyticsOrder & { step?: number },
  customer?: AnalyticsCustomer,
) => track("Checkout Step Viewed", { ...order, customer });

export const trackCheckoutStepCompleted = (
  order: AnalyticsOrder & { step?: number },
  customer?: AnalyticsCustomer,
) => track("Checkout Step Completed", { ...order, customer });

export const trackButtonClicked = (
  name: string,
  customer?: AnalyticsCustomer,
) => track("Button Clicked", { name, customer });

export const trackPaymentInfoEntered = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) =>
  track("Payment Info Entered", {
    ...order,
    customer,
  });

export const trackOrderCompleted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) => {
  track("Order Completed", {
    ...order,
    customer,
  });
};

export const trackSwapStarted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) => track("Swap Started", { ...order, customer })

export const trackSwapSelectionStarted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) => track("Swap Selection Started", { ...order, customer })

export const trackSwapFunnelStarted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) => track("Swap Funnel Started", {...order, customer })

export const trackSwapFunnelGradingCompleted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) => track("Swap Funnel Grading Completed", { ...order, customer })

export const trackSwapFunnelShippingCompleted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) => track("Swap Funnel Shipping Completed", { ...order, customer })

export const trackSwapFunnelPaymentCompleted = (
  order: AnalyticsOrder,
  customer?: AnalyticsCustomer,
) => track("Swap Funnel Payment Completed", { ...order, customer })

export const trackProductViewed = (
  product: AnalyticsProduct,
  customer?: AnalyticsCustomer,
) =>
  track("Product Viewed", {
    ...product,
    customer,
  });

export const trackProductClicked = (
  product: AnalyticsProduct,
  customer?: AnalyticsCustomer,
) => track("Product Clicked", { ...product, customer });

export const trackProductAdded = (
  product: AnalyticsProduct,
  customer?: AnalyticsCustomer,
) => track("Product Added", { ...product, customer });

export const trackProductRemoved = (
  product: AnalyticsProduct,
  customer?: AnalyticsCustomer,
) => track("Product Removed", { ...product, customer });

export const trackProductListViewed = (
  productList: AnalyticsProductList,
  customer?: AnalyticsCustomer,
) => track("Product List Viewed", { ...productList, customer });

export const trackCouponEntered = (
  coupon: AnalyticsCoupon,
  customer?: AnalyticsCustomer,
) => track("Coupon Entered", { ...coupon, customer });

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useTrack = (f: () => any) => useEffect(f, []);
