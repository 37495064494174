import type { NextPage } from "next";
import { Router } from "next/router";

import { useTrackPage } from "shared/analytics/useTrackPage/index";
import { useTrack } from "utils/analytics/analytics.utils";

export function withTrackPageView<TProps>(Component: NextPage<TProps>) {
  return function (props: TProps) {
    const { trackPageView } = useTrackPage();

    useTrack(() => {
      // track initial page view
      trackPageView();

      // track page views on route change
      Router.events.on("routeChangeComplete", trackPageView);

      return () => {
        Router.events.off("routeChangeComplete", trackPageView);
      };
    });

    return <Component {...props} />;
  };
}
