import Script from "next/script";

import { config } from "utils/config.utils";

export const GoogleTagManager = () => (
  <>
    <Script>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://stm.mobile.club/2ddcjyonv1nq8iy.js?aw='+i.replace(/^GTM-/, '')+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${config.gtmContainerId}');
      `}
    </Script>
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${config.gtmContainerId}`}
        height="0"
        width="0"
        style={{
          display: "none",
          visibility: "hidden",
        }}
      ></iframe>
    </noscript>
  </>
);
