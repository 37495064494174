import { fromCustomer } from "shared/analytics/useTrackOrder/mappers/analytics-customer.mapper";
import { useTrackPageLazyQuery } from "shared/analytics/useTrackPage/track-page.query.generated";
import { trackPageView } from "utils/analytics/analytics.utils";

type TrackingFunction = typeof trackPageView;

export const useTrackPage = () => {
  const [page] = useTrackPageLazyQuery({ fetchPolicy: "network-only" });

  const track = (trackingFunction: TrackingFunction) => () =>
    page().then(({ data }) => trackingFunction(fromCustomer(data?.customer)));

  return {
    trackPageView: track(trackPageView),
  };
};
